$breakpoint: 700px;
$breakpoint-large: 1200px;

@mixin mobile {
  @media only screen and (max-width: $breakpoint - 1px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin large-desktop {
  @media only screen and (min-width: $breakpoint-large) {
    @content;
  }
}
