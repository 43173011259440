@use 'breakpoints' as *;

:root {
  --container-max-width: 600px;
  --safe-area-inset-bottom: env(safe-area-inset-bottom, 0px);
  --safe-area-inset-top: env(safe-area-inset-top, 0px);

  --border-radius-button: 0.6rem;
  --border-radius-content: 1.8rem;

  --height-navbar-top-mobile: 80px;
  --height-navbar-desktop: 180px;

  --overlay-inset-bottom: var(--height-navbar-mobile);

  @include desktop {
    --overlay-inset-bottom: 0px;
  }
}

body {
  background-color: #ebebeb;
  overflow-y: scroll;

  @include mobile {
    overflow-x: hidden;
    scrollbar-width: 0;
  }
}

@include mobile {
  ::-webkit-scrollbar {
    width: 0;
  }
}

.route {
  max-width: var(--container-max-width);
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

ax-logo {
  display: block;
  margin: 1.5rem auto;
  width: 100px;
}
